import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";

// Assets

const CareersJobPosting = () => (
  <Layout currentPage="careers-listing">
    <div className="container">
      <Link to="/careers#alljobs">
        <p className="backbutton col-md-12">Back to Careers</p>
      </Link>
      <h1 className="jobtitle col-md-12">Android Developer</h1>
      <div className="jobdescription col-md-12"> </div>
      <div className="row col-md-12 justify-content-md-between">
        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You will:
          </div>
        </div>
        <div className="col-md-9">
          <ul className="aligned-left jobsection">
            <li>
              Be an integral part of our digital product team responsible for
              feature design, debugging, and product maintenance of Uno’s
              Android mobile applications.
            </li>
            <li>
              Utilize Bluetooth Low Energy and other communication protocols to
              seamlessly link our hardware devices to our client apps and server
              software.
            </li>
            <li>
              Refine and implement Android APIs that allow higher level code to
              access communication functionality in a simple manner: features
              like simple device setup, automatic data synchronization, and real
              time device coordination experiences.
            </li>
            <li>
              Work within cross-functional feature teams and collaborate closely
              with firmware engineers, designers, and product managers.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">You are:</div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>
              A problem solver who finds solutions and implements them
              autonomously.
            </li>
            <li>Energized by a fast-paced environment.</li>
            <li>Methodical in your approach to writing and testing code.</li>
            <li>Able to properly assess task timing and hit milestones.</li>
            <li>
              Sensitive to user experience and code optimizations that improve
              it.
            </li>
            <li>
              Able to communicate effectively with interdisciplinary team.
            </li>
            <li>
              Able to evaluate solutions both in both pragmatic and ideal terms.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You bring:
          </div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>BS/MS/PHD in Computer Science or related field.</li>
            <li>
              4+ years of professional software development experience—at least
              2 years of those working with the Android SDK.
            </li>
            <li>Knowledge of Android Design Patterns and best practices.</li>
            <li>Expertise in Java.</li>
            <li>
              Strong understanding of software design, architecture,
              data-structures, multi-threading, testing, security, and
              performance.
            </li>
            <li>
              Hands on and proven experience with algorithms, data structures,
              and design patterns as well as object oriented design principles.
            </li>
            <li>
              Ability to improve performance via Instruments and other profiling
              tools
            </li>
            <li>Previous experience working in agile development workflow.</li>
            <li>Ability to develop and consume RESTful network APIs.</li>
            <li>Experience designing application level protocols.</li>
            <li>
              Experience being a part of a team that released an Android app to
              the Google Play Store.
            </li>
            <li>Experience working at a startup. </li>
            <li>
              Experience releasing and managing Android apps in the Google Play
              Store is a plus.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You apply:
          </div>
        </div>
        <div className="col-md-9">
          <div className="aligned-left" style={{ paddingBottom: "100px" }}>
            By sending us an <a href="mailto:careers@introducing.uno">email</a>{" "}
            with your resume and a cover letter.
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default CareersJobPosting;
